<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="maintenance/maintain-plan/page"
      @selection-change="handleSelectionChange"
    >
      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <!--      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">{{$l("common.add", "新增")}}</el-button>-->
      <!--      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">{{$l("common.add", "从合同中导入")}}</el-button>-->
      <el-button
        v-if="$auth('保养计划管理新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l("common.add", "新增")}}
      </el-button>
      <el-button
        v-if="$auth('保养计划管理导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <el-button
        v-if="$auth('保养计划管理批量修改类型班组')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.batch.open(selectList)">
        {{$l("common.add", "批量修改类型班组")}}
      </el-button>
      <el-button
        v-if="$auth('保养计划管理批量删除')"
        slot="toolbar"
        style="margin-left: 4px"
        type="primary"
        size="mini"
        @click="deleteBatch(selectList)">
        {{$l("common.delete", "批量删除")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="楼宇">
            <el-input v-model.trim="filter.buildingName" clearable></el-input>
          </vm-search>
          <vm-search label="维保班组">
            <el-input v-model.trim="filter.teamName" clearable></el-input>
          </vm-search>

          <vm-search label="队长姓名">
            <el-input v-model="filter.teamLeaderName"></el-input>
          </vm-search>

          <vm-search label="保养类型">
            <vm-dict-select v-model="filter.type" type="maintainType"></vm-dict-select>
          </vm-search>
          <vm-search label="计划保养日期">
            <el-date-picker
              v-model="filter.pdTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
          <vm-search label="实际保养日期">
            <el-date-picker
              v-model="filter.adTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model="filter.regCode"></el-input>
          </vm-search>
          <vm-search label="使用单位">
            <el-input v-model="filter.customerCompanyName" type="elevatorUseStatus"></el-input>
          </vm-search>
          <vm-search label="客户姓名">
            <el-input v-model="filter.factoryCode" type="elevatorUseStatus"></el-input>
          </vm-search>
          <vm-search label="客户电话">
            <el-input v-model="filter.factoryCode" type="elevatorUseStatus"></el-input>
          </vm-search>
          <vm-search label="是否完成">
            <el-select v-model="filter.completed">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
        </div>
      </template>

      <!--      <el-table-column align="center" type="index" width="50"></el-table-column>-->
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column prop="teamName" :label="$l('project.name','维保班组')">
        <el-table-column prop="teamLeaderName" label="队长">
          <template slot-scope="scope">
            <div>
              {{scope.row.teamName}}
            </div>
            <div> {{scope.row.teamLeaderName}}/{{scope.row.teamLeaderPhone}}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="realEstateName" label="楼盘/楼宇" align="center">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}/{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="typeDesc" label="保养类型">
      </el-table-column>
      <el-table-column prop="planDate" label="计划保养日期">
        <template slot-scope="scope">
          <span></span>
          {{scope.row.planDate?$moment(scope.row.planDate).format("YYYY-MM-DD"):""}}
        </template>
      </el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center"></el-table-column>
      <el-table-column prop="brandName" label="品牌/型号" align="center">
        <template slot-scope="scope">
          {{scope.row.brandName}}/{{scope.row.modelName}}
        </template>
      </el-table-column>
      <el-table-column prop="customerCompanyName" label="使用单位" align="center"></el-table-column>
      <el-table-column prop="contactName" label="客户姓名/电话" align="center">
        <template slot-scope="scope">
          {{scope.row.contactName}}/{{scope.row.contactPhone}}
        </template>
      </el-table-column>
      <el-table-column prop="actualDate" label="实际保养日期" align="center"></el-table-column>
      <el-table-column prop="completed" label="是否完成" align="center">
        <template slot-scope="scope">
          {{scope.row.completed?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('保养计划管理编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('保养计划管理删除')" type="danger" @click="deleteRow(scope.row)">{{$l('common.delete','删除')}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <maintain-plan-batch ref="batch" @save-success="getList(-1)"></maintain-plan-batch>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/maintainPlanNew.xlsx"
        action="maintenance/maintain-plan/import"
        name="保养计划导入"
        download-name="保养计划模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./PlanEdit.vue";
  import MaintainPlanBatch from "./MaintainPlanBatch";
  import {export2Excel} from "@/util";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";

  export default {
    components: { EditPage, MaintainPlanBatch},
    props:{
      activeName:String,
    },
    data() {
      return {
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
          orderColumnName:"planDate",
          maintenanceType:"regulation",
          companyId: loginUtil.getCompanyCode(),
        },
        value1:"",
        senior:false,
        visible:false,
        selectList:[],
        maintainPlanIds:[],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      exportExcelData() {
        const title={
          teamName:"维保班组",
          teamLeaderName:"队长",
          teamLeaderPhone:"联系电话",
          typeDesc:"保养类型",
          planDate:"计划保养日期",
          customerCompanyName:"使用单位",
          realEstateName:"楼盘",
          buildingName:"楼宇",
          elevatorName:"内部编号",
          regCode:"注册代码",
          completed:"是否完成",
        };
        this.$http.get("maintenance/maintain-plan/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`保养工单-审批完成-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      handleSelectionChange(val) {
        this.selectList = val;
      },
      handleTime() {
        if(this.filter.pdTime !=null) {
          this.filter.planDateFrom = this.filter.pdTime[0];
          this.filter.planDateTo = moment(this.filter.pdTime[1]).format("YYYY-MM-DD")+" 23:59:59";
        }
        if(this.filter.adTime !=null) {
          this.filter.actualDateFrom = this.filter.adTime[0];
          this.filter.actualDateTo = moment(this.filter.adTime[1]).format("YYYY-MM-DD")+" 23:59:59";
        }
      },

      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible = false;
      },
      handleSeniorClick() {
        this.senior=!this.senior;
      },
      deleteRow(row) {
        this.$confirm("确定删除该保养计划吗?", this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/maintain-plan/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success("保养计划" + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      deleteBatch(selectList) {
        const arr = [];
        selectList.forEach(item=>{
          arr.push(item.id);
        });
        this.maintainPlanIds=arr;
        this.$confirm(`确定批量删除${selectList.length}条保养计划吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/maintain-plans/${this.maintainPlanIds}`).then(() => {
            this.getList(-1);
            this.$message.success(this.$l("common.deleteSuccessTip", "批量删除成功"));
          });
        });
      },
      changeMission(row) {
        console.log(row,122);
        this.$emit("changeTab",row);
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
